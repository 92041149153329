<template>
  <div class="user-manage-container">
    <el-card class="serach-area">
      <el-input v-model="mobile" placeholder="输入手机号可以进行模糊查询" @keyup.enter="searchUser">
        <template #append>
          <el-button @click="searchUser" :icon="Search" />
        </template>
      </el-input>

      <div class="mt" v-if="showAdminOperation">
        <el-checkbox v-model="selectInviter" label="我邀请的" size="large" @change="getListData" />
        <el-checkbox v-model="selectAgency" label="我的代理" size="large" @change="getListData" />
      </div>
    </el-card>

    <el-card>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column label="#" type="index" />
        <el-table-column prop="username" label="用户名">
        </el-table-column>
        <el-table-column prop="mobile" label="手机号">
        </el-table-column>
        <el-table-column :label="$t('msg.excel.avatar')" align="center">
          <template v-slot="{ row }">
            <el-image class="avatar" :src="row.avatar" :preview-src-list="[row.avatar]"></el-image>
          </template>
        </el-table-column>
        <el-table-column :label="$t('msg.excel.role')">
          <template #default="{ row }">
            <div v-if="row.role && row.role.length > 0">
              <el-tag v-for="item in row.role" :key="item.id" size="mini">{{
                item.title
              }}</el-tag>
            </div>
            <div v-else>
              <el-tag size="mini">{{ $t('msg.excel.defaultRole') }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="注册时间">
          <template #default="{ row }">
            {{ $filters.dateFilter(row.registerTime, 'YYYY-MM-DD HH:mm:ss') }}
          </template>
        </el-table-column>
        <el-table-column prop="restTokens" label="剩余tokens" />
        <el-table-column label="备注" align="center">
          <template v-slot="{ row, $index }">
            <!-- <span>{{ row.remark }}</span> -->
            <template v-if="editingRowIndex === $index">
              <UpdateRemark :record="row" :finishEditing="finishEditing" />
            </template>
            <template v-else>
              <span @dblclick="switchToEditing($index)">{{ row.remark || '空' }}</span>
            </template>
          </template>
        </el-table-column>
        <el-table-column :label="$t('msg.excel.action')" fixed="right" width="260">
          <template #default="{ row }">
            <el-button type="primary" size="mini" @click="onShowClick(row.id)">查看</el-button>
            <el-button type="info" size="mini" @click="onOperationClick(row)">操作</el-button>
            <el-button v-permission="['freezeUser']" type="danger" size="mini" @click="onRemoveClick(row)">冻结</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination class="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="page" :page-sizes="[2, 5, 10, 20]" :page-size="size"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>

    <Operation v-model="operationDialogVisible" :selectUser="selectUser" @updateList="getListData"></Operation>
  </div>
</template>

<script setup>
import { ref, onActivated, computed } from 'vue'
import { getUserManageList } from '@/api/user-manage'
import { watchSwitchLang } from '@/utils/i18n'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import Operation from './components/Operation.vue'
import { Search } from '@element-plus/icons-vue'
import { useStore } from 'vuex'
import UpdateRemark from './components/UpdateRemark.vue'
import { deepClone } from '@/utils/utils'

// 数据相关
const tableData = ref([])
const total = ref(0)
const page = ref(1)
const size = ref(10)
const mobile = ref('')
const selectInviter = ref(false)
const selectAgency = ref(false)

const store = useStore()

const showAdminOperation = computed(() =>
  // [6, 7, 8, 9] 表示为 角色id
  [6, 7, 8, 9].includes(store.getters.maxlevel.id)
)

// 获取数据的方法
const getListData = async () => {
  const result = await getUserManageList({
    page: page.value,
    size: size.value,
    mobile: mobile.value,
    inviterCode: selectInviter.value ? store.getters.userInfo?.inviterCode : '',
    agencyCode: selectAgency.value ? store.getters.userInfo?.agencyCode : ''
  })
  tableData.value = result.list
  total.value = result.total
}
getListData()
// 监听语言切换
watchSwitchLang(getListData)

// 根据手机号进行查询
const searchUser = () => {
  if (mobile.value?.length > 11) {
    ElMessage({
      message: '手机号不能超过11位数',
      type: 'warning'
    })
    return
  }

  page.value = 1
  getListData()
}

// 分页相关
/**
 * size 改变触发
 */
const handleSizeChange = (currentSize) => {
  size.value = currentSize
  getListData()
}

/**
 * 页码改变触发
 */
const handleCurrentChange = (currentPage) => {
  page.value = currentPage
  getListData()
}

const router = useRouter()

// 处理导入用户后数据不重新加载的问题
onActivated(getListData)

/**
 * 冻结用户按钮点击事件
 */
const onRemoveClick = (row) => {
  console.log('功能正在开发中')
}

/**
 * 查看按钮点击事件
 */
const onShowClick = (id) => {
  router.push(`/user/info/${id}`)
}

/**
 * 操作按钮的点击事件
 */
const operationDialogVisible = ref(false)
const selectUser = ref('')
const onOperationClick = (row) => {
  selectUser.value = row
  operationDialogVisible.value = true
}

/**
 * 更新备注
 */
const editingRowIndex = ref(-1)

const finishEditing = (val) => {
  const index = deepClone(editingRowIndex.value)

  if (tableData.value[index]) {
    tableData.value[index].remark = val
  }

  editingRowIndex.value = -1
}

const switchToEditing = (index) => {
  console.log('switchToEditing', index)
  editingRowIndex.value = index
}
</script>

<style lang="scss" scoped>
.user-manage-container {
  .serach-area {
    margin-bottom: 12px;
  }

  .header {
    margin-bottom: 22px;
    text-align: right;
  }

  ::v-deep .avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  ::v-deep .el-tag {
    margin-right: 6px;
  }

  .pagination {
    margin-top: 20px;
    text-align: center;
  }

  .mt {
    margin-top: 24px;
  }
}
</style>
