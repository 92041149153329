<template>
  <el-dialog title="充值" :model-value="modelValue" @close="closed">
    <div class="user-wrap">
      <span>正在给</span>
      <el-tag class="ml-2" type="success">{{ selectUser.mobile }}</el-tag>
      <span>进行充值</span>
    </div>

    <el-input v-model="money" type="number" placeholder="请输入金额" />

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closed">{{ $t('msg.universal.cancel') }}</el-button>
        <el-button type="primary" @click="onConfirm">{{
          $t('msg.universal.confirm')
        }}</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineProps, defineEmits, ref } from 'vue'
import { rechargeForUser } from '@/api/user'
import { ElMessage } from 'element-plus'
import { useStore } from 'vuex'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  selectUser: {
    type: Object,
    required: true
  }
})

const emits = defineEmits(['update:modelValue', 'updateList'])

const money = ref('')

const store = useStore()

const onConfirm = async () => {
  try {
    if (!money.value) {
      ElMessage.error('请输入充值金额')
      return
    }
    if (money.value < 0) {
      ElMessage.error('充值金额必须大于0')
      return
    }
    const params = {
      userId: props.selectUser.id,
      money: money.value
    }
    await rechargeForUser(params)
    // 更新列表
    updateListData()
    // 刷新用户信息
    store.dispatch('user/getUserInfo')
  } catch (error) {
    console.log('onConfirm--error', error)
  }
}

/**
 * 关闭
 */
const closed = () => {
  emits('update:modelValue', false)
}

const updateListData = () => {
  closed()
  emits('updateList')
}
</script>

<style lang="scss" scoped>
.user-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  .ml-2 {
    margin-left: 12px !important;
  }
}
</style>
