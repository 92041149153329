<template>
  <div class="user-info-container">
    <el-card class="user-container">
      <template #header>
        <div class="header">
          <span>个人信息</span>
        </div>
      </template>

      <div class="user-profile">
        <!-- 头像 -->
        <div class="box-center">
          <pan-thumb
            :image="detailData.avatar"
            :height="'100px'"
            :width="'100px'"
            :hoverable="false"
          >
            <div>Hello</div>
          </pan-thumb>
        </div>
        <!-- 姓名 && 角色 -->
        <div class="box-center">
          <div class="user-name text-center">
            {{ detailData.username }}
          </div>
        </div>

        <div class="info">
          <el-descriptions :column="2" border>
            <el-descriptions-item label="用户名">{{
              detailData.username
            }}</el-descriptions-item>
            <el-descriptions-item label="手机号">{{
              detailData.mobile
            }}</el-descriptions-item>
            <el-descriptions-item label="余额">{{
              detailData.remainingSum
            }}</el-descriptions-item>
            <el-descriptions-item label="累积消费">
              {{ detailData.cumulativeSpending }}
            </el-descriptions-item>
            <el-descriptions-item label="剩余tokens">{{
              detailData.restTokens
            }}</el-descriptions-item>
            <el-descriptions-item label="累积消耗tokens">{{
              detailData.totalTokens
            }}</el-descriptions-item>
            <el-descriptions-item label="累积提问">{{
              detailData.questionsNum
            }}</el-descriptions-item>
            <el-descriptions-item label="注册时间">{{
              $filters.dateFilter(
                detailData.registerTime,
                'YYYY-MM-DD HH:mm:ss'
              )
            }}</el-descriptions-item>
            <el-descriptions-item label="GPT3有效期">{{
              $filters.dateFilter(detailData.expireAt, 'YYYY-MM-DD HH:mm:ss')
            }}</el-descriptions-item>
            <el-descriptions-item label="GPT4有效期">{{
              $filters.dateFilter(detailData.expireAt4, 'YYYY-MM-DD HH:mm:ss')
            }}</el-descriptions-item>
            <el-descriptions-item label="分享链接" :span="2">
              {{ `https://gpt-plus.huat.xyz/#/?channel=${detailData.uuid}` }}
            </el-descriptions-item>
            <el-descriptions-item label="我的角色" :span="2">
              <div v-if="detailData.role && detailData.role.length">
                <el-tag
                  v-for="(role, index) in detailData.role"
                  :key="index"
                  class="ml-2"
                  :type="getRoundColor()"
                  >{{ role.title }}</el-tag
                >
              </div>
              <div v-else>
                <el-tag :key="index" class="ml-2" :type="getRoundColor()"
                  >普通用户</el-tag
                >
              </div>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script setup>
import { defineProps, ref } from 'vue'
// import { useStore } from 'vuex'
import { getRoundColor } from '@/utils/utils'
import { watchSwitchLang } from '@/utils/i18n'
import { userDetail } from '@/api/user-manage'
import PanThumb from '@/components/PanThumb/index.vue'

const props = defineProps({
  id: {
    type: String,
    required: true
  }
})

// 数据相关
// const store = useStore()
// const detailData = computed(() => store.getters.userInfo)

const detailData = ref({})

const getUserDetail = async () => {
  detailData.value = await userDetail(props.id)
}
getUserDetail()
// 语言切换
watchSwitchLang(getUserDetail)
</script>

<style lang="scss" scoped>
.print-box {
  margin-bottom: 20px;
  text-align: right;
}

.ml-2 {
  margin-right: 12px;
}

.info {
  margin-top: 24px;
}

.user-profile {
  text-align: center;
  .user-name {
    font-weight: bold;
  }

  .box-center {
    padding-top: 10px;
  }

  .user-role {
    padding-top: 10px;
    font-weight: 400;
  }
}

.user-info-box {
  width: 1024px;
  margin: 0 auto;
  .title {
    text-align: center;
    margin-bottom: 18px;
  }
  .header {
    display: flex;
    ::v-deep .el-descriptions {
      flex-grow: 1;
    }
    .avatar {
      width: 187px;
      box-sizing: border-box;
      padding: 30px 20px;
      border: 1px solid #ebeef5;
      border-left: none;
    }
    .remark {
      margin-right: 12px;
    }
  }
  .body {
    ul {
      list-style: none;
      li {
        span {
          margin-right: 62px;
        }
      }
    }
  }
  .foot {
    margin-top: 42px;
    text-align: right;
  }
}
</style>
