import request from '@/utils/request'

// 给某个用户充值
export const rechargeForUser = data => {
  return request({
    url: '/v1/back/rechargeForUser',
    method: 'POST',
    data
  })
}

// 更新用户信息
export const updateUser = data => {
  return request({
    url: '/v1/back/updateUser',
    method: 'POST',
    data
  })
}
