<template>
  <el-dialog title="操作" :model-value="modelValue" @close="closed">
    <el-button v-permission="['distributeRole']" @click="onShowRoleClick">
      授权
    </el-button>

    <el-button v-permission="['recharge']" @click="onShowRechargeClick">
      充值
    </el-button>

    <!-- 授权弹窗 -->
    <RolePanel
      v-model="roleDialogVisible"
      :userId="selectUserId"
      @updateRole="updateListData"
    ></RolePanel>

    <!-- 充值弹窗 -->
    <Recharge
      v-model="rechargeDialogVisible"
      :selectUser="selectUser"
      @updateList="updateListData"
    ></Recharge>
  </el-dialog>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue'
import RolePanel from './roles.vue'
import Recharge from './Recharge.vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  selectUser: {
    type: Object,
    required: true
  }
})

const emits = defineEmits(['update:modelValue', 'updateList'])

/**
 * 授权角色的点击事件
 */
const roleDialogVisible = ref(false)
const selectUserId = ref('')
const onShowRoleClick = () => {
  selectUserId.value = props.selectUser.id
  roleDialogVisible.value = true
}

// 保证每次打开重新获取用户角色数据
watch(roleDialogVisible, (val) => {
  if (!val) selectUserId.value = ''
})

/**
 * 充值
 */
const rechargeDialogVisible = ref(false)
const onShowRechargeClick = () => {
  rechargeDialogVisible.value = true
}

/**
 * 关闭
 */
const closed = () => {
  emits('update:modelValue', false)
}

const updateListData = () => {
  closed()
  emits('updateList')
}
</script>

<style lang="scss" scoped></style>
