import request from '@/utils/request'

/**
 * 获取用户列表数据
 */
export const getUserManageList = data => {
  return request({
    url: '/v1/back/getUserList',
    params: data
  })
}

/**
 * 删除指定数据 -- 本项目暂未使用
 */
export const deleteUser = id => {
  return request({
    url: `/api/user-manage/detele/${id}`
  })
}

/**
 * 获取所有用户列表数据 -- 本项目暂未使用
 */
export const getUserManageAllList = () => {
  return request({
    url: '/api/user-manage/all-list'
  })
}

/**
 * 获取用户详情
 */
export const userDetail = id => {
  return request({
    url: `/v1/back/getUserDetail/${id}`
  })
}

/**
 * 获取指定用户角色
 */
export const userRoles = id => {
  return request({
    url: `/v1/back/getUserRole/${id}`
  })
}

/**
 * 更新用户角色
 */
export const updateRole = (id, roles) => {
  return request({
    url: `/v1/back/updateUserRole/${id}`,
    method: 'POST',
    data: {
      roles
    }
  })
}
