<template>
  <div>
    <el-input ref="inputRef" v-model="remark" @keyup.enter="handleEnter" placeholder="请输入备注" />
  </div>
</template>

<script setup>
import { defineProps, onMounted, ref } from 'vue'
import { updateUser } from '@/api/user'
import { ElMessage } from 'element-plus'

const props = defineProps({
  record: {
    type: Object,
    default: () => ({}),
    required: true
  },
  finishEditing: {
    type: Function,
    required: true
  }
})

const remark = ref('')
const inputRef = ref(null)

onMounted(() => {
  inputRef.value.focus()
})

const handleEnter = async () => {
  try {
    const payload = {
      ...props.record,
      remark: remark.value
    }

    await updateUser(payload)

    ElMessage({
      message: '更新成功',
      type: 'success'
    })

    // 更新表格的输入框状态
    const fn = props.finishEditing
    if (typeof fn === 'function') {
      fn(remark.value)
    }
  } catch (error) {
    console.log('handleEnter--err', error)
  }
}

</script>

<style lang="scss" scoped></style>
